import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-4" }
const _hoisted_2 = { class: "text-lg mb-4" }
const _hoisted_3 = { class: "flex flex-col space-y-2" }
const _hoisted_4 = {
  key: 0,
  class: "p-2 mt-4 font-semibold rounded text-danger-500 size-18"
}
const _hoisted_5 = {
  key: 1,
  class: "document-portal flex flex-col h-screen"
}
const _hoisted_6 = { class: "bg-white border-b py-3" }
const _hoisted_7 = { class: "container" }
const _hoisted_8 = { class: "flex space-x-4 justify-between items-center" }
const _hoisted_9 = { class: "py-4 md:py-8 lg:py-16 flex-1" }
const _hoisted_10 = { class: "container h-full" }
const _hoisted_11 = ["data"]
const _hoisted_12 = {
  key: 1,
  class: "p-10 flex items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_LoginLayout = _resolveComponent("LoginLayout")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_PageLoading = _resolveComponent("PageLoading")!

  return (!_ctx.pdf)
    ? (_openBlock(), _createBlock(_component_LoginLayout, { key: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('platform.document-portal.enter-code-instructions')), 1),
            _createVNode(_component_BaseTextInput, {
              modelValue: _ctx.otp,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.otp) = $event)),
              label: _ctx.$t('platform.document-portal.enter-code'),
              placeholder: _ctx.$t('platform.document-portal.enter-verification-code'),
              error: _ctx.errors.otp ? _ctx.errors.otp[0] : null
            }, null, 8, ["modelValue", "label", "placeholder", "error"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_BaseButton, {
                disabled: _ctx.loading,
                type: "button",
                color: "ghost",
                class: "btn-large",
                onClick: _ctx.requestOTPForDocument
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.document-portal.resend-code')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]),
              _createVNode(_component_BaseButton, {
                disabled: _ctx.loading,
                class: "btn-xl",
                onClick: _ctx.getPdf
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.login.login')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ])
          ]),
          (_ctx.errorMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createElementVNode("header", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_BaseIcon, {
                name: "bpm",
                class: "text-primary-500 text-4xl"
              }),
              _createVNode(_component_BaseButton, {
                color: "ghost",
                "left-icon": "signout",
                onClick: _ctx.signout
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.error.logout')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            (_ctx.pdf)
              ? (_openBlock(), _createElementBlock("object", {
                  key: 0,
                  type: "application/pdf",
                  data: `data:application/pdf;base64,${_ctx.pdf}`,
                  class: "w-full h-full border shadow rounded"
                }, null, 8, _hoisted_11))
              : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_PageLoading)
                ]))
          ])
        ])
      ]))
}