
import { Vue, Options } from 'vue-class-component';
import { AxiosError } from 'axios';
import { PageLoading, BaseButton, BaseTextInput, BaseIcon } from '@/lib/components';
import LoginLayout from '@/lib/layouts/LoginLayout.vue';
import { DocumentService } from '@/services/api';

@Options({
  props: {
    documentId: {
      type: String,
      required: true
    }
  },
  components: {
    PageLoading,
    BaseButton,
    BaseIcon,
    BaseTextInput,
    LoginLayout
  }
})
export default class PatientDocumentPage extends Vue {
  documentId!: string;
  loading = false;

  documentService = new DocumentService();

  errors: { [key: string]: Array<string> } = {};
  errorMessage = '';

  otp = '';
  pdf = '';

  mounted() {
    this.requestOTPForDocument();
  }

  signout() {
    this.pdf = '';
    this.otp = '';
    this.errorMessage = '';
  }

  async requestOTPForDocument() {
    this.loading = true;
    this.errorMessage = '';
    try {
      await this.documentService.requestOTPForDocument(this.documentId);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  async getPdf() {
    this.loading = true;
    this.errorMessage = '';
    try {
      this.pdf = await this.documentService.fetchBase64Pdf(this.documentId, {
        params: {
          code: this.otp
        }
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  handleError(error: AxiosError) {
    if (!error.response || error.response.status === 500) {
      this.errorMessage = this.$t('platform.document-portal.error-500') as string;
      return;
    }

    if (error.response.status === 403) {
      this.errorMessage = this.$t('platform.document-portal.error-403') as string;
      return;
    }

    if (error.response.status === 404) {
      this.errorMessage = this.$t('platform.document-portal.error-404') as string;
      return;
    }

    if (error.response.data.message) {
      this.errorMessage = error.response.data.message;
      return;
    }

    this.errorMessage = this.$t('platform.document-portal.error') as string;
  }
}
